<template>
  <div id="payment-list">
    <!-- <v-card class="mb-8">
      <v-card-title>
        {{ t('Payments') }}
      </v-card-title>

      <v-row class="px-2 ma-0 pb-2 text-center">
        <v-col
          cols="12"
          sm="2"
        >
          <v-text-field
            v-model="searchQuery"
            single-line
            dense
            outlined
            hide-details
            :placeholder="t('search')"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-fab-transition v-if="hasPermission(129)">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              color="secondary"
              dark
              absolute
              top
              right
              fab
              class="mb-4 me-3"
              v-on="on"
              @click="onCreate"
            >
              <v-icon>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ t("tooltip.new") }}</span>
        </v-tooltip>
      </v-fab-transition>
    </v-card> -->

    <v-card>
      <v-card-title>
        {{ t('tooltip.filters') }}
      </v-card-title>

      <v-row class="px-2 ma-0 text-center">
        <v-col
          cols="12"
          sm="3"
        >
          <v-select
            v-model="statusFilter"
            :items="statusOptions"
            item-text="text"
            item-value="value"
            single-line
            outlined
            dense
            hide-details
            clearable
            :placeholder="t('select.status')"
          ></v-select>
        </v-col>

        <v-col
          cols="12"
          sm="2"
        >
          <v-menu
            v-model="modalFrom"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateFrom"
                :placeholder="t('transactions.from_date')"
                :prepend-icon="icons.mdiCalendar"
                outlined
                readonly
                dense
                clearable
                class="invoice-list-status"
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateFrom"
              color="secundary"
              :max="dateTo ? dateTo : maxDate"
              @input="modalFrom = false"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col
          cols="12"
          sm="2"
        >
          <v-menu
            v-model="modalTo"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateTo"
                :placeholder="t('transactions.to_date')"
                :prepend-icon="icons.mdiCalendar"
                outlined
                readonly
                dense
                clearable
                class="invoice-list-status"
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateTo"
              color="secundary"
              :max="maxDate"
              :min="dateFrom"
              @input="modalTo = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-divider />

      <v-row class="px-2 ma-0 text-center">
        <v-col
          cols="12"
          sm="12"
          md="3"
        >
          <v-text-field
            v-model="searchQuery"
            :placeholder="t('search')"
            outlined
            hide-details
            dense
          >
          </v-text-field>
        </v-col>

        <v-spacer />

        <v-col
          v-if="hasPermission(128)"
          cols="12"
          md="2"
          sm="12"
          class="d-flex flex-row"
        >
          <v-btn
            color="secondary"
            block
            @click="exportExcel('/payment/file/')"
          >
            <img
              :src="icons.export"
              height="20px"
              width="20px"
              alt="export"
              attrs="export"
              style="margin-right: 5px; background-position: center center"
            />
            {{ t('tooltip.export') }}
          </v-btn>
        </v-col>

        <v-col
          v-if="hasPermission(129)"
          cols="12"
          sm="2"
        >
          <v-btn
            color="secondary"
            block
            @click="onCreate"
          >
            <v-icon>
              {{ icons.mdiPlus }}
            </v-icon>
            {{ t('tooltip.add') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-divider />

      <v-data-table
        :headers="computedTableColumns"
        :items="listTable"
        :loading="loading"
        class="text-no-wrap"
        :loading-text="t('table.loading_text')"
        :no-data-text="t('table.no_data')"
        :header-props="{sortByText: t('table.sort_by')}"
        :footer-props="{itemsPerPageText: t('table.item_page'), 'items-per-page-options':[10, 15, 25, 50]}"
        :items-per-page="-1"
        :search.sync="searchQuery"
        :sort-desc.sync="descSort"
        :options.sync="options"
        :server-items-length="totalListTable"
      >
        <!-- id -->
        <template #[`item.id`]="{item}">
          <router-link
            class="font-weight-medium text-decoration-none"
            :style="$vuetify.theme.dark ? 'color: #e7e3fcad' : '#3b0000de' "
            :to="{ name: 'views-pos-payment-preview', params: { id: item.id, dataParams: item, pageParams: options, filterParams: filters } }"
          >
            #{{ item.id }}
          </router-link>
        </template>

        <!-- facility -->
        <template #[`item.facility`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              color="primary"
              :class="item.facility.logo ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <v-img
                v-if="item.facility.logo"
                :src="item.facility.logo"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(item.facility.facility_name) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <span>
                {{ item.facility.facility_name }}
              </span>
              <!-- <small>@{{ item.name }}</small> -->
            </div>
          </div>
        </template>

        <!-- payment_date -->
        <template #[`item.payment_date`]="{item}">
          <span class="text-no-wrap">{{ item.payment_date }}</span>
        </template>

        <!-- amount -->
        <template #[`item.amount`]="{item}">
          <span class="text-no-wrap">{{ formatCurrency(item.amount) }}</span>
        </template>

        <!-- status -->
        <template #[`item.status`]="{item}">
          <v-chip
            small
            :color="resolveClientAvatarVariant(item.status)"
            :class="`${resolveClientAvatarVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.status_str }}
          </v-chip>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <div class="d-flex align-center justify-center">
            <!-- view  -->
            <v-tooltip
              v-if="hasPermission(130)"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  color="info"
                  v-bind="attrs"
                  :to="{ name: 'views-pos-payment-preview', params: { id: item.id, dataParams: item, pageParams: options, filterParams: filters } }"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiEyeOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span>View Payment</span>
            </v-tooltip>

            <!-- print -->
            <v-tooltip
              v-if="hasPermission(131)"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  color="error"
                  v-bind="attrs"
                  v-on="on"
                  @click="onPrint(item)"
                >
                  <v-icon size="18">
                    {{ icons.mdiPrinter }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ t('tooltip.print_receivet') }}</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <vue-html2pdf
      v-show="!hideHtml2pdf"
      ref="html2Pdf"
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :enable-links="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="computedPaymentData.id ? `Receipt #${computedPaymentData.id} ${computedPaymentData.payment_date}` : 'Receipt'"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      :html-to-pdf-options="{
        html2canvas: {
          scrollX: 0,
          scrollY: 0,
        },
      }"
      @beforeDownload="beforeDownload()"
      @hasDownloaded="hasDownloaded()"
    >
      <section
        slot="pdf-content"
        style="background-color: #ffffff; width: 100%; height: 100%"
      >
        <pos-payment-history-report
          :data-params="computedPaymentData"
          :has-transaction-video="hasProducts"
        ></pos-payment-history-report>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import {
  mdiEyeOutline,
  mdiPrinter,
  mdiPlus,
} from '@mdi/js'

import { onMounted, ref, computed } from '@vue/composition-api'
import { getGroupsActive } from '@api'
import { avatarText } from '@core/utils/filter'
import { info, success } from '@core/utils/toasted'
import { formatCurrency, useRouter } from '@core/utils'
import useCryptoJs from '@/@core/utils/useCryptoJs'
import exportExcel from '@core/utils/useExportExcel'

import VueHtml2pdf from 'vue-html2pdf'
import PosPaymentHistoryReport from '@/templates/reports/PosPaymentHistoryReport.vue'

import usePaymentList from './usePaymentList'

export default {
  components: {
    VueHtml2pdf,
    PosPaymentHistoryReport,
  },
  setup() {
    const { userData } = useCryptoJs()
    const { route, router } = useRouter()
    const {
      listTable,
      totalListTable,
      computedTableColumns,
      searchQuery,
      statusFilter,
      groupFilter,
      dateFrom,
      dateTo,
      maxDate,
      filters,
      options,
      loading,
      descSort,
      modalFrom,
      modalTo,

      t,
      resolveClientAvatarVariant,
      fetchPagedPayments,
      hasPermission,
      setFilters,
      setOptions,
    } = usePaymentList()

    const groupsOptions = ref([])
    const hideHtml2pdf = ref(true)
    const html2Pdf = ref(null)
    const initPaymentData = ref({
      id: null,
      amount: null,
      description: null,
      payment_date: null,
      status: null,
      status_str: null,
      facility: {
        facility_name: null,
        logo: null,
        email: null,
        phone: null,
      },
      user: {
        username: null,
        fullname: null,
      },
      user_app: {
        username: null,
        fullname: null,
      },
      terminal_name: null,
      guest_name: null,
      email: null,
      phone_number: null,
      payment_method: {
        id: null,
        name: null,
        info: {
          last4: null,
          brand: null,
          exp_month: null,
          exp_year: null,
        },
      },
      products: [],
      invoice_subtotal: null,
      invoice_discount: null,
      invoice_tax: null,
      invoice_tip: null,
      invoice_amount: null,
      note: null,
    })
    const paymentData = ref(initPaymentData.value)
    const computedPaymentData = computed(() => paymentData.value)
    const hasProducts = computed(() => {
      if (paymentData.value) {
        return !paymentData.value.products.some(e => e.name === null)
      }

      return false
    })

    const statusOptions = computed(() => [
      { text: t('status.successful'), value: 'S' },
      { text: t('status.declined'), value: 'D' },
      { text: t('status.refunded'), value: 'R' },
    ])

    const generateReport = () => {
      html2Pdf.value.generatePdf()
    }

    const beforeDownload = () => {
      info('Generating PDF!')
    }

    const hasDownloaded = () => {
      success('PDF download successful!')
      paymentData.value = initPaymentData.value
    }

    const onPrint = item => {
      paymentData.value = item
      setTimeout(() => {
        generateReport()
      }, 1000)
    }

    const onCreate = () => {
      router.push({
        name: 'views-payment-form',
        params: {
          option: 1,
          pageParams: options.value,
          filterParams: filters.value,
        },
      })
    }

    onMounted(async () => {
      const { filterParams, pageParams } = route.value.params
      setOptions(pageParams)
      setFilters(filterParams)

      if (userData.value && userData.value.role === 'A') {
        const groups = await getGroupsActive()
        groupsOptions.value = groups.data
      }

      await fetchPagedPayments()

      setTimeout(() => {
        hideHtml2pdf.value = false
      }, 300)
    })

    return {
      searchQuery,
      statusOptions,
      statusFilter,
      groupFilter,
      dateFrom,
      dateTo,
      maxDate,
      filters,
      options,
      descSort,
      totalListTable,
      listTable,
      loading,
      groupsOptions,
      userData,
      computedTableColumns,
      modalFrom,
      modalTo,

      hideHtml2pdf,
      html2Pdf,
      paymentData,
      computedPaymentData,
      hasProducts,

      // i18n
      t,

      avatarText,
      resolveClientAvatarVariant,
      formatCurrency,
      hasPermission,
      setFilters,
      setOptions,
      fetchPagedPayments,
      onPrint,
      generateReport,
      beforeDownload,
      hasDownloaded,
      onCreate,
      exportExcel,

      icons: {
        mdiEyeOutline,
        mdiPrinter,
        mdiPlus,
        export: require('@/assets/images/svg/export.svg'),
      },
    }
  },
}
</script>

<style lang="scss" scoped>
#payment-list {
  .payment-list-actions {
    max-width: 7.81rem;
  }
  .payment-list-search {
    max-width: 10.625rem;
  }
  .payment-list-status {
    max-width: 11.3rem;
  }
}
</style>
